import React, { useContext } from 'react'
import { Link } from 'gatsby'
import Firebase from 'gatsby-plugin-firebase'

import { AuthProvider, AuthContext } from '../components/auth/auth'
import Layout from '../components/layout'

// markup
const IndexPage = ({location}) => {
  const [data, setData] = React.useState(null)

  React.useEffect(() => {
    if (Firebase.auth().isSignInWithEmailLink(window.location.href)) {
      // Additional state parameters can also be passed via URL.
      // This can be used to continue the user's intended action before triggering
      // the sign-in operation.
      // Get the email if available. This should be available if the user completes
      // the flow on the same device where they started it.
      var email = window.localStorage.getItem('emailForSignIn');
      console.log(email)
      if (!email) {
        // User opened the link on a different device. To prevent session fixation
        // attacks, ask the user to provide the associated email again. For example:
        email = window.prompt('Please provide your email for confirmation');
      }
      // The client SDK will parse the code from the link for you.
      Firebase.auth().signInWithEmailLink(email, window.location.href)
        .then(function(result) {
          // Clear email from storage.
          window.localStorage.removeItem('emailForSignIn');
          // You can access the new user via result.user
          // Additional user info profile not available via:
          // result.additionalUserInfo.profile == null
          // You can check if the user is new or existing:
          // result.additionalUserInfo.isNewUser
        })
        .catch(function(error) {
          // Some error occurred, you can inspect the code: error.code
          // Common errors could be invalid email and invalid or expired OTPs.
        });
    }
    Firebase
      .firestore()
      .collection('rooms')
      .onSnapshot(snapshot => {

        let rooms = []

        snapshot.forEach(function(doc) {
          const roomId = doc.id
          const roomIdAttr = `room-${roomId}`
          rooms.push(
            <div id={roomIdAttr} key={roomIdAttr}>
              <Link to={`rooms/${roomId}`}>{roomId}</Link>
            </div>
          )
        })

        setData(rooms)
      })
  })
  function createRoom() {
    // Add to user profile
    // if (currentUser) {
    //   Firebase.firestore().collection('users').where('email','==',currentUser.email).onSnapshot(snapshot => {
    //     snapshot.forEach(function(doc) {
    //       const userDocId = doc.id
    //       Firebase.firestore().collection('users').doc(userDocId).update({
    //         upvotes: Firebase.firestore.FieldValue.arrayUnion(storyId)
    //       })
    //     })
    //   })
    // }
    Firebase.firestore().collection('rooms').add({
      background: '#000000',
      members: [],
      waitingroom: []
    })
  }
  return (
    <AuthProvider>
      <Layout>
        <main>
          {/* <title>Coworker</title>
          <h1>
            Create a room <span role='img' aria-label='friendly emojis'>🙇‍♂️</span>
          </h1>
          <h4>
            Invite your friends <span role='img' aria-label='friendly emojis'>👋</span>
          </h4>
          <h4>
            Hangout and work <span role='img' aria-label='friendly emojis'>💿</span>
          </h4> */}
          <button onClick={createRoom}>Create a Room</button>
          {data}
        </main>
      </Layout>
    </AuthProvider>
  )
}

export default IndexPage
